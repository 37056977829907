//
// Initialize plugins and behaviors on document ready
//

$(function (){

    masonry.init()

    navbar.init()

    AOS.init()

    switchButton.init()

    infobulle.init()

    buttonCard.init()

    swiper.init()

    modalAcess.init()

    voletContent.init()

    bntReturn.init()

    sidebarNav.init()

    carouselSlider.init()

    tabList.init()

   // telInput.init()

  //  tooltip.init()
    
  //  spinButton.init()


})


var masonry = {
    init: function(){
        $('.grid').masonry({
            transitionDuration: 0,
            itemSelector: ".grid-item",
            fitWidth: true,
            gutter: 20
        });
    }
}

//
// Add extra behavior to the navbar
//

var navbar = {
    init: function () {
        if (!window.utils.isMobile()) {
            this.initDropdownHover()
        }
    },
    // show/hide dropdown on hover
    initDropdownHover: function () {
        var $dropdowns = $('.navbar .dropdown')
        $dropdowns.each(function (index, item) {
            var $item = $(this)

            $item.hover(function () {
                $item.addClass("show")
                $item.find("> .dropdown-toggle").attr("aria-expanded", true)
                $item.find("> .dropdown-menu").addClass("show")
            }, function () {
                $item.removeClass('show')
                $item.find("> .dropdown-toggle").attr("aria-expanded", false)
                $item.find("> .dropdown-menu").removeClass("show")
            })
        })
    },

}


//
// Extra Helpers
//

window.utils = {
    isMobile: function () {
        if (window.innerWidth <= 992) {
            return true
        } else {
            return false
        }
    }
}

//
// cartes de choix
//

var buttonCard = {
    init: function () {
        $('.a-btnCard').click( function () {
            $(this).toggleClass('');
        })
    }
}

//
// Switch button
//

var switchButton = {

    init: function () {
        $('.a-switch').click(function (evt) {

            let el = evt.target;

            if (el.getAttribute("aria-checked") == "true") {
                el.setAttribute("aria-checked", "false");
            } else {
                el.setAttribute("aria-checked", "true");
            }

        })
    }
}




//
// disparition de l'infobulle au clic sur le bouton de fermeture
//

var infobulle = {
    init: function () {
        $('.openInfobulle').on( "mouseleave", function () {
            $(this).next().hide(200);
        });
        $('.openInfobulle').on( "mouseenter", function () {
            $(this).next().show(200);
        });
        $('.openInfobulle').focus(function (){
            $(this).next().show(200);
        });
        $('.closeInfobulle').focus(function () {
            $('.m-infobulle').hide(200);
        });
    }
}


//
// swiper
//

var swiper = {
    init: function () {
        $("[data-toggle='swiper']").each(function(index, item) {
            new Swiper(item, $(item).data('options'))
        })
    }
}


//
// switch focus  between close & button - forbidden focus to the container of modal
//
var modalAcess = {
    init: function () {
        $(document).ready(function () {
//            $('#m-findIdentifiantHelpModalId, #o-dragAndDropModalId').on('shown.bs.modal', function () {
//                $('.getFocus').trigger('focus');
//            });
//            $('#m-findIdentifiantHelpModalId, #o-dragAndDropModalId').focus(function () {
//                $('.getFocus').trigger('focus');
//            });
        	$('.modal').on('shown.bs.modal', function () {
                $('.getFocus').trigger('focus');
            });
            $('.modal').focus(function () {
                $('.getFocus').trigger('focus');
            });
        })
    }
}


//
// Volet button
//

var voletContent = {
    init: function () {

    var countTime = 0;
    var header = $('.o-voletContent');
    var buttonInfo = $('.a-btn.-languette');
    if(!header.hasClass("visible")) {
        var TimeInterval = setInterval(function() {
            ++countTime;
            header.addClass('visible');
            buttonInfo.attr('aria-expanded', 'true');
            $('.o-voletContent__boxText').show();
            $('.o-voletContent__closeButton').show();       
            if(countTime >= 2){
                clearInterval(TimeInterval);
                header.removeClass('visible');
                buttonInfo.attr('aria-expanded', 'false');
                $('.o-voletContent__boxText').hide();
                $('.o-voletContent__closeButton').hide();  
            }
        }, 4000);
    }
        buttonInfo.on('click', function () {
            header.toggleClass('visible');
            if(header.hasClass('visible')) {
                buttonInfo.attr("aria-expanded", "true");
                $('.o-voletContent__boxText').show();
                $('.o-voletContent__closeButton').show();                
            } else {
                buttonInfo.attr("aria-expanded", "false");
                setTimeout(function() {
                    $('.o-voletContent__boxText').hide();
                    $('.o-voletContent__closeButton').hide();
                }, 500);
            }
            
        })
        $('.a-btn.getCloseHelp').on('click', function () {
            $('.o-voletContent').removeClass('visible');
            $('.a-btn.-languette').attr("aria-expanded", "false");
            setTimeout(function() {
                $('.o-voletContent__boxText').hide();
                $('.o-voletContent__closeButton').hide();
            }, 500);
            
        })
    }
}


var bntReturn = {
    init: function () {
        $(document).ready(function () {
            $("[id*='return']").click(function(event) {
                event.preventDefault();
                window.location.href = "${backURL}";
            });
        })
    }
}

var sidebarNav = {
    init: function(){
        // $('.sidebarNavOpen').on('click', function(){
        //     $('.m-sidebarNav').addClass('active');
        //     $('.overlay').addClass('active');

        //     $('html').addClass('overflow-hidden');
        // })

        // $('.sidebarNavClose, .overlay').on('click', function(){
        //     $('.m-sidebarNav').removeClass('active');
        //     $('.overlay').removeClass('active');

        //     $('html').removeClass('overflow-hidden');
        // })

        $(document).on('click', '.sidebarClose', function () {
            $('.modal-backdrop').remove();
            $('#sidebarNav').modal('hide');
        });

    }
}
var carouselSlider = {
    init: function(){
        $('.carousel-indicators li').on('click', function() {
            $(this).attr('aria-selected', 'true');
            $(this).attr('tabindex', "0");
            $('.carousel-indicators li').not(this).attr('aria-selected', 'false');
            $('.carousel-indicators li').not(this).attr('tabindex', "-1");
        })

        $('.carousel-indicators li').keydown(function(event) { 
            var nextElement = null;
            switch (event.key) {
                case "ArrowLeft":
                    var leftElement = null
                    if(this.previousElementSibling === null){
                        leftElement = this.parentElement.lastElementChild;
                    } else{
                        leftElement = this.previousElementSibling;
                    }
                    nextElement = leftElement;
                break;
                case "ArrowRight": 
                    var rightElement = null;
                    if(this.nextElementSibling === null){
                        rightElement = this.parentElement.firstElementChild;
                    } else{
                        rightElement = this.nextElementSibling;
                    } 
                    nextElement = rightElement;
                break;
                default:return;
            }
            nextElement.focus();
            nextElement.classList.add('active');
            nextElement.setAttribute('tabindex', 0);
            nextElement.setAttribute('aria-selected', 'true');

            this.setAttribute('aria-selected', 'false');
            this.setAttribute('tabindex', -1);
            this.classList.remove('active');
        })

    }
}

var tabList = {
    init: function(){
        $('.m-signatureNav li a').on('click', function() {
            $(this).attr('aria-selected', 'true');
            $(this).attr('tabindex', "0");
            $('.m-signatureNav li a').not(this).attr('aria-selected', 'false');
            $('.m-signatureNav li a').not(this).attr('tabindex', "-1");
        })

        $('.m-signatureNav li').keydown(function(event) { 
            var nextElement = null;
            switch (event.key) {
                case "ArrowLeft":
                    var leftElement = null
                    if(this.previousElementSibling === null){
                        leftElement = this.parentElement.lastElementChild;
                    } else{
                        leftElement = this.previousElementSibling;
                    }
                    nextElement = leftElement;
                break;
                case "ArrowRight": 
                    var rightElement = null;
                    if(this.nextElementSibling === null){
                        rightElement = this.parentElement.firstElementChild;
                    } else{
                        rightElement = this.nextElementSibling;
                    } 
                    nextElement = rightElement;
                break;
                default:return;
            }
            nextElement.firstElementChild.focus();
            nextElement.firstElementChild.classList.add('active');
            nextElement.firstElementChild.setAttribute('tabindex', 0);
            nextElement.firstElementChild.setAttribute('aria-selected', 'true');

            this.firstElementChild.setAttribute('aria-selected', 'false');
            this.firstElementChild.setAttribute('tabindex', -1);
            this.firstElementChild.classList.remove('active');
        })

    }
}

var telInput = {
    init: function(){
        var errorMap = ["Invalid number", "Invalid country code", "Too short", "Too long", "Invalid number"];
        var input = document.querySelector("#phone");
        var iti =  intlTelInput(input, {
            initialCountry: "fr",
            preferredCountries: ["fr", "us", "ru", "de", "es"],
            separateDialCode : false,
            utilsScript:
            "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
          });
          input.addEventListener('blur', function() {
            if (input.value.trim()) {
              if (iti.isValidNumber()) {
                console.log("hide");
              } else {
                input.classList.add("error");
                var errorCode = iti.getValidationError();
                var errorMsg = errorMap[errorCode];
                console.log(errorMsg);
              }
            }
          });
         
    }
}



// var spinButton = {
//     init: function(){
//         /** @type {!Element} The input element. */
//         let inputEl;
//         /** @type {!Element} The button up element. */
//         let incrementButtonEl;
//         /** @type {!Element} The button down element. */
//         let decrementButtonEl;
  
//         /** @type {number} The value as a number. */
//         let valueNumber;
//         /** @type {string} The string value of the input. */
//         let valueText;
  
//         /** @type {number} Constant representing aria-valuemax. */
//         let MIN_VALUE;
//         /** @type {number} Constant representing aria-valuemin. */
//         let MAX_VALUE;
//         /** @type {number} Constant representing the initial aria-valuenow. */
//         let INITIAL_VALUE;
//         /** @type {number} Constant representing increment/decrement size. */
//         let STEP_SIZE;
  
//         function init() {
//           inputEl = document.getElementById('input-spin-size');
//           incrementButtonEl = document.getElementById('increment');
//           decrementButtonEl = document.getElementById('decrement');
//           MIN_VALUE = Number(inputEl.getAttribute('aria-valuemin'));
//           MAX_VALUE = Number(inputEl.getAttribute('aria-valuemax'));
//           valueNumber = Number(inputEl.getAttribute('aria-valuenow'));
//           valueText = inputEl.value;
//           INITIAL_VALUE = valueNumber;
//           STEP_SIZE = 1;
//           setEventListeners();
//         }
  
//         function setEventListeners() {
//           inputEl.addEventListener('input', handleInputInput, false);
//           inputEl.addEventListener('keydown', handleInputKeyDown, false);
//           incrementButtonEl.addEventListener('click', handleIncrementButtonClick, false);
//           decrementButtonEl.addEventListener('click', handleDecrementButtonClick, false);
//         }
  
//         function handleIncrementButtonClick(e) {
//           increment();
//           inputEl.focus();
//         }
  
  
//         function handleDecrementButtonClick(e) {0
//           decrement();
//           inputEl.focus();
//         }
  
//         /**
//         * Sets input field to default upon blur of the empty input.
//         * Sets value to default if value is no longer valid.
//         * Otherwise truncates value and updates aria-valuenow.
//         * @param {!Event} e
//         */
//         function handleInputInput(e) {
//           if (!input.value || input.value === '-' || input.value === '.') {
//             // clear the value.
//             inputEl.setAttribute('aria-valuenow', '');
//             valueNumber = null;
//             valueText = input.value;
//             return;
//           }
//           let newValueNumber = Number(input.value);
//           if (isNaN(newValueNumber)) {
//             input.value = valueText;
//           } else {
//             // Note that this will set an invalid aria-valuenow.
//             // Bug tracking how and when to modify aria-valuenow:
//             // https://github.com/w3c/aria-practices/issues/704
//             setValue(newValueNumber);
//           }
//         }
  
//         /**
//         * Switch that handles all valid non-numerical key downs.
//         * @param {!Event} e
//         */
//         function handleInputKeyDown(e) {
//           switch (e.key) {
//             case 'ArrowUp':
//               increment();
//               break;
//             case 'ArrowDown':
//               decrement();
//               break;
//             case 'Home':
//               setValue(MIN_VALUE);
//               break;
//             case 'End':
//               setValue(MAX_VALUE);
//               break;
//             default:
//               return;
//           }
//           e.preventDefault();
//           e.stopPropagation();
//         }
  
//         // add jsdoc
//         function increment() {
//           if (valueNumber >= MAX_VALUE) {
//             setValue(MAX_VALUE);
//           } else if (valueNumber < MIN_VALUE) {
//             setValue(MIN_VALUE);
//           } else {
//             setValue(valueNumber + STEP_SIZE)
//           }
//         }
  
//         // add jsdoc
//         function decrement() {
//           if (valueNumber <= MIN_VALUE) {
//             setValue(MIN_VALUE);
//           } else if (valueNumber > MAX_VALUE) {
//             setValue(MAX_VALUE);
//           } else {
//             setValue(valueNumber - STEP_SIZE)
//           }
//         }
  
//         /**
//         * Sets value property and aria-valuenow attribute together.
//         * @param {number} newValue
//         */
//         function setValue(newValue) {
//           inputEl.value = newValue;
//           inputEl.setAttribute('aria-valuenow', newValue);
//           valueNumber = newValue;
//           valueText = inputEl.value;
//         }
  
  
//         window.addEventListener('load', init, false);
//     }
// }

// var tooltip = {
//     init: function(){
//         'use strict';
        
//             /*
//              * jQuery accessible simple (non-modal) tooltip window, using ARIA
//              * @version v2.2.0 
//              * Website: https://a11y.nicolas-hoffmann.net/simple-tooltip/
//              * License MIT: https://github.com/nico3333fr/jquery-accessible-simple-tooltip-aria/blob/master/LICENSE
//              */
        
//             function accessibleSimpleTooltipAria(options) {
//                 var element = $(this);
//                 options = options || element.data();
//                 var text = options.simpletooltipText || '';
//                 var prefix_class = typeof options.simpletooltipPrefixClass !== 'undefined' ? options.simpletooltipPrefixClass + '-' : '';
//                 var content_id = typeof options.simpletooltipContentId !== 'undefined' ? '#' + options.simpletooltipContentId : '';
        
//                 var index_lisible = Math.random().toString(32).slice(2, 12);
//                 var aria_describedby = element.attr('aria-describedby') || '';
        
//                 element.attr({
//                     'aria-describedby': ('label_simpletooltip_' + index_lisible + ' ' + aria_describedby).trim()
//                 });
        
//                 element.wrap('<span class="' + prefix_class + 'simpletooltip_container"></span>');
        
//                 var html = '<span class="js-simpletooltip ' + prefix_class + 'simpletooltip" id="label_simpletooltip_' + index_lisible + '" role="tooltip" aria-hidden="true">';
        
//                 if (text !== '') {
//                     html += '' + text + '';
//                 } else {
//                     var $contentId = $(content_id);
//                     if (content_id !== '' && $contentId.length) {
//                         html += $contentId.html();
//                     }
//                 }
//                 html += '</span>';
        
//                 $(html).insertAfter(element);
//             }
        
//             // Bind as a jQuery plugin
//             $.fn.accessibleSimpleTooltipAria = accessibleSimpleTooltipAria;
        
//             $(document).ready(function() {
        
//                 $('.js-simple-tooltip')
//                     .each(function() {
//                         // Call the function with this as the current tooltip
//                         accessibleSimpleTooltipAria.apply(this);
//                     });
        
//                 // events ------------------
//                 $('body')
//                     .on('mouseenter focusin', '.js-simple-tooltip', function() {
//                         var $this = $(this);
//                         var aria_describedby = $this.attr('aria-describedby');
//                         var tooltip_to_show_id = aria_describedby.substr(0, aria_describedby.indexOf(" "));
//                         var $tooltip_to_show = $('#' + tooltip_to_show_id);
//                         $tooltip_to_show.attr('aria-hidden', 'false');
//                     })
//                     .on('mouseleave', '.js-simple-tooltip', function(event) {
//                         var $this = $(this);
//                         var aria_describedby = $this.attr('aria-describedby');
//                         var tooltip_to_show_id = aria_describedby.substr(0, aria_describedby.indexOf(" "));
//                         var $tooltip_to_show = $('#' + tooltip_to_show_id);
//                         var $is_target_hovered = $tooltip_to_show.is(':hover');
        
//                         //alert($target_hovered);
//                         //$target.addClass('redborder');
//                         if (!$is_target_hovered) {
//                             $tooltip_to_show.attr('aria-hidden', 'true');
//                         }
//                     })
//                     .on('focusout', '.js-simple-tooltip', function(event) {
//                         var $this = $(this);
//                         var aria_describedby = $this.attr('aria-describedby');
//                         var tooltip_to_show_id = aria_describedby.substr(0, aria_describedby.indexOf(" "));
//                         var $tooltip_to_show = $('#' + tooltip_to_show_id);
        
//                         $tooltip_to_show.attr('aria-hidden', 'true');
//                     })
//                     .on('mouseleave', '.js-simpletooltip', function() {
//                         var $this = $(this);
//                         $this.attr('aria-hidden', 'true');
//                     })
//                     .on('keydown', '.js-simple-tooltip', function(event) {
//                         // close esc key
        
//                         var $this = $(this);
//                         var aria_describedby = $this.attr('aria-describedby');
//                         var tooltip_to_show_id = aria_describedby.substr(0, aria_describedby.indexOf(" "));
//                         var $tooltip_to_show = $('#' + tooltip_to_show_id);
        
//                         if (event.keyCode == 27) { // esc
//                             $tooltip_to_show.attr('aria-hidden', 'true');
//                         }
//                     });
//             });
//     }
// }

